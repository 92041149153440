import React from "react";
import { pdficon, downloadpdf, downloadbannerimg } from "../Assets/index";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "../ContextApi";
import Header from "../components/Header";
import HeaderTabs from "../components/HeaderTabs";
import NoDataFound from "../components/NoDataFound";
import ContentLoader from "react-content-loader";
import { NotokenFound } from "../Utils";
import ClaimsFeatures from "../components/ClaimsFeatures";
import Wellness_Benefits from "./Wellness_Benefits";
import HeroSection from "../components/HeroSection";
import { mainAPikey } from "../ContextApi";

const Downloadforms = () => {
  const [downloadapiData, setDownloadapiData] = useState([]);
  const [documentpath, setDocumentpath] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const [EmptyDocumentmsg, setDocumentmsg] = useState("");
  const {
    activeElementId,
    policytypeidcontext,
    constantsApiData,
    requestOptions,
    setRequestOptions,
  } = useContext(AppContext);
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const [appLinkToken , setappLinkToken] = useState(localStorage.getItem("appLinkToken"))
  const navigate = useNavigate();

  function handleTokenInResponse(response) {
    if (response.status === 401 || token === "") {
      NotokenFound(navigate);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        setToken(authorizationToken);
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });

        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (token && activeElementId && policytypeidcontext) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/${mainAPikey}/employee/download-forms-list?policy_id=${activeElementId}&policy_type_id=${policytypeidcontext}`,
            requestOptions
          );

          const data = await handleTokenInResponse(response);
          const res = await data.json();

          if (res.success) {
            setDownloadapiData(res.data);
            setDocumentpath(constantsApiData?.path?.UTILITIES_DOCUMENT_PATH);
          } else {
            setDownloadapiData(res.data);
            setDocumentmsg(res.message);
          }

          setDataLoading(false);
        } catch (error) {
          console.error("Error fetching forms list:", error);
        }
      }
    };

    fetchData();
  }, [token, activeElementId, policytypeidcontext]);


  useEffect(() => {
    if (!token) {
      NotokenFound(navigate);
    }
  }, []);

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);

  return (
    <>
      <Header />
      <HeroSection/>
      <div className="claims-tabs-section-cover-wrapper">
        <div className="row">
          <div className="col-lg-4">
            <div className="claims-header-tabs">
              <ClaimsFeatures />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="claims-content-cover-wrapper">
              <div className="download-main-cover-wrapper">
                <h3 className="text-center header-Text">Download Forms</h3>
                <div className="download-main-section-wrapper">
                  <div className="download-section-cover">
                    <div className="container">
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-5">
                          <div className="row">
                            {isDataLoading ? (
                              <ContentLoader />
                            ) : downloadapiData.length === 0 ? (
                              <NoDataFound msg={EmptyDocumentmsg} />
                            ) : (
                              downloadapiData.map((elem, ind) => (
                                <div className="col-lg-12 mb-2" key={elem.id}>
                                  <Downloadformlist
                                    data={elem}
                                    path={documentpath}
                                  />
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="download-forms-banner-img">
                            <img src={downloadbannerimg} alt="download-icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
    position="top-center"
/>
    </>
  );
};

const Downloadformlist = ({ data, path }) => {

  const handleDownload = (url) => {
    if (url.startsWith("https://")) {
      window.open(url, "_blank");
    } else {
      window.open(`${path}/${url}`, "_blank");
    }
  };

  return (
    <div className="download-form-content-box">
      <li className="download-form-list">
        <div className="download-form-list-header">
          <div className="pdf-icon-box">
            <img src={pdficon} alt />
          </div>
          <h5>{data.document_name}</h5>
        </div>
        <div
          className="download-icon-box"
          onClick={() => {
            handleDownload(`${data.file_name}`);
          }}
        >
          <img src={downloadpdf} alt="pdf-download-icon" />
        </div>
      </li>
    </div>
  );
};

const EmptyDataComponent = ({ msg }) => {
  return (
    <>
      <h4>{msg}</h4>
    </>
  );
};

export default Downloadforms;
