import React from "react";
import Header from "../components/Header";
import { useEffect, useContext, useState , useRef } from "react";
import { AppContext } from "../ContextApi";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NotokenFound } from "../Utils";
import HeroSection from "../components/HeroSection.js";
import InsuranceProducts from "../components/InsuranceProducts.js";
import Packages from "../components/Packages.js";
import { mainAPikey } from "../ContextApi";
import { HOME_INDEX_API } from "../Constants.js";


const Policydetails = () => {
  const [policyDetailsdata, setpolicyDetailsdata] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const [dataMsg, setdataMsg] = useState("");
  const [ispackageLoading, setPackageLoading] = useState(true);
  const [packagesdata, setPackagesData] = useState([]);
  const [logos, setLogos] = useState("");
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const {
    activeElementId,
    requestOptions,
    setRequestOptions,
    tabapiData,
    featuresType,
  } = useContext(AppContext);
  const navigate = useNavigate();

  const [appLinkToken, setappLinkToken] = useState(null);
  const HomeScrollRef = useRef(null);

  const requestOptionsNew = { ...requestOptions };
  requestOptionsNew.method = "POST";

  function handleTokenInResponse(response) {
    if (response.status === 401 || token === "") {
      NotokenFound(navigate);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });
        setToken(authorizationToken);

        return updatedResponse;
      } else {
        return response;
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      if (activeElementId) {
        setDataLoading(true);

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/${mainAPikey}/employee/policy-details/?policy_id=${activeElementId}`,
            requestOptions
          );

          if (response.status === 429) {
            toast.error("Oops! You have reached maximum limit, please try again after sometime.");
          }

          const data = await handleTokenInResponse(response);
          const res = await response.json();

          if (res.success) {
            setpolicyDetailsdata(res.data);
            setLogos(res.logo);
          } else {
            setpolicyDetailsdata(res.data);
            setdataMsg(res.message);
            setLogos(res.logo);
          }

          setDataLoading(false);

          if (!token) {
            NotokenFound(navigate);
          }
        } catch (error) {
          if (error.message === 'Too Many Requests') {
            toast.error('Too many requests. Please try again later.');
          } else {
            console.error("Error fetching data:", error);
          }

          setDataLoading(false);
        }
      }
    };

    fetchData();
  }, [activeElementId]);

  useEffect(() => {
    if (!token) {
      NotokenFound(navigate);
    }
  }, []);

 

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
    if (mainAPikey) {
      async function getV1TokenAutoLogin() {
        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL + `/${mainAPikey}/employee/auto-login`,
            requestOptionsNew
          );
          const res = await handleTokenInResponse(response);
          const data = await res.json();
          console.log(data , "DATAYTIUTYT");
          if (data.success === true) {
            localStorage.setItem("appLinkToken", data?.data?.token);
            setappLinkToken(data?.data?.token);
          }
        } catch (error) {
          console.error(error);
        }
      }
      getV1TokenAutoLogin();
    }
  }, [token, mainAPikey]);

  const fetchDataIndex = async () => {
    const cleanedToken = appLinkToken?.startsWith("Bearer ")
      ? appLinkToken.replace("Bearer ", "")
      : appLinkToken;

    const customRequestOption = { ...requestOptions };
    customRequestOption.headers = {
      ...customRequestOption.headers,
      Authorization: appLinkToken,
      Token: "MED_PAC",
      "X-Api-Key": "jHs7L9pQ2mH7qR1oV5jL8vG9dP3cY5nA",
    };

    try {
      const homeIndexRes = await fetch(
        `${HOME_INDEX_API}`,
        customRequestOption
      );

      if (!homeIndexRes.ok) {
        console.error(
          "API error:",
          homeIndexRes.status,
          homeIndexRes.statusText
        );
        const errorData = await homeIndexRes.json().catch(() => null);
        console.error("Error details:", errorData);
        throw new Error("Network response was not ok");
      }

      const homeIndexData = await homeIndexRes.json();
      console.log(homeIndexData);
      setPackagesData(homeIndexData?.active_package);
      if (homeIndexData?.active_package?.length > 0) {
        setPackageLoading(false);
      }
    } catch (error) {
      console.error("Error fetching home index:", error);
    }
  };
  useEffect(() => {
    if (appLinkToken) {
      fetchDataIndex();
    }
  }, [appLinkToken]);

  useEffect(() =>{
    console.log(packagesdata);
  },[packagesdata])

  return (
    <>
      <div className="claim-app-home-main-cover-wrapper" >
        <Header />
        <HeroSection scrollToSectionRef={HomeScrollRef} appLinkToken={appLinkToken}/>
        <div className="packages-section-main-cover-wrapper">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <InsuranceProducts />
              </div>
              <div className="col-lg-6">
                <Packages
                  appLinkToken={appLinkToken}
                  packagesdata={packagesdata}
                  ispackageLoading={ispackageLoading}
                />

            </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

const EmptyDataComponent = ({ msg }) => {
  return (
    <>
      <h4>{msg}</h4>
    </>
  );
};

export default Policydetails;
